import toastr from "toastr";
import Splide from "@splidejs/splide";
window.toastr = toastr;
window.Splide = Splide;

// Elemts animate display
const elements = Array.from(document.getElementsByClassName("op-0"));
if (elements.length > 0) {
  let i = 0;
  do {
    showElem(i);
    i++;
  } while (i < elements.length);
}
function showElem(i) {
  setTimeout(() => {
    elements[i].classList.add("active");
  }, 300 * i);
}

const menuTrigger = document.getElementById("menu-trigger");
const topNav = document.getElementById("top-nav");
menuTrigger.addEventListener("click", () => {
  console.log("test");
  topNav.classList.toggle("active");
  menuTrigger.classList.toggle("active");
});
